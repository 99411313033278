body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* main menu */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navbar ul li {
  position: relative;
}

.navbar ul li a {
  display: block;
  padding: 10px 15px;
  color: #000;
  text-decoration: none;
}

.navbar ul li ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
}

.navbar ul li ul li {
  width: 200px;
}

.navbar ul li:hover > ul {
  display: block;
}

.navbar ul li ul li a {
  padding: 10px;
  white-space: nowrap;
}

.navbar ul li ul li a:hover {
  background-color: #f5f5f5;
}

